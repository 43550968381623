export const IntellessiHeaderData = {
  title: "Интеллесси",
  paragraph: [
    "Интеллектуальный ассистент для автоматизации внутренних бизнес-процессов компании.",
    "Технологичный чат-бот, работающий на основе больших языковых моделей, предназначен для трансформации процесса поиска информации по данным компании, сокращения времени на нахождение релевантных ответов.",
  ],
  documents: [
    {
      title: "Лицензионный договор",
      type: "IntellessiLA",
      file: "Интеллесси.Лицензионный_договор.pdf",
      description: "В данном документе содержится ... (ЛД)",
    },
    {
      title: "Инструкция пользователя",
      type: "IntellessiUM",
      file: "Интеллесси.Инструкция_пользователя.pdf",
      description: "В данном документе содержится ... (ИП)",
    },
    {
      title: "Функциональное описание",
      type: "IntellessiFD",
      file: "Интеллесси.Функциональное_описание.pdf",
      description: "В данном документе содержится ... (ФО)",
    },
    {
      title: "Инструкция по развертыванию",
      type: "IntellessiDI",
      file: "Интеллесси.Развёртывание_виртуальной_машины_с_ПО_Интеллесси_на_гипервизоре_Proxmox_VE_8.2.2.pdf",
      description: "В данном документе содержится ... (ИпР)",
    },
  ],
  btn: "Оставить заявку",
};
